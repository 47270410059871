import { Link } from 'gatsby'
import React from 'react'
import Icon from './icons'

import styles from './profile-card.module.css'
import { Image } from './Image'
function ProfileCard ({ profileColor, name, lastName, socialLinkedin, position, team, department, slug }) {
  const handleStorage = (slug) => {
    localStorage.setItem('lastVisitedPerson', slug)
  }

  return (
    <div className={styles.profileCard} data-teams={team} data-slug={slug?.current} data-member={name} data-lastname={lastName}>
      <Link to={`/our-people/${slug?.current}/`} onClick={() => handleStorage(slug?.current)} />
      <div className={styles.profileMediaThumb}>
        {profileColor && profileColor.asset && (
          <Image
            imgObj={profileColor}
            className={styles.colorImage}
            props={{
              alt: name,
              width: 392,
              height: 694,
              queryParams: {
                q: 100
              }
            }}
          />
        )}
      </div>
      <div className={styles.personInfo}>
        <div className={styles.personName}>
          <h5 className={`${styles.headline} ${styles.bullet}`}>
            {name}
          </h5>
        </div>
        {position && (
          <div className={styles.bio}>
            {position}{department && department.length > 0 ? ',' : ''} <span>{department && department.join(', ').replace('-', ' ')}</span>
          </div>
        )}
      </div>
      {socialLinkedin !== null && (
        <a href={socialLinkedin} target='_blank' rel='noreferrer'><span>LinkedIn</span>
          <Icon symbol='linkedIn' />
        </a>
      )}
    </div>
  )
}

export default ProfileCard
